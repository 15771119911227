/* eslint-disable react/prop-types */
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

const NextArrow = ({ onClick }) => (
  <div
    className="absolute top-1/2 w-12 h-12 bg-black flex justify-center items-center opacity-50 text-white transform -translate-y-1/2 -right-8 z-10 cursor-pointer"
    onClick={onClick}
  >
    <IoIosArrowForward size={30} />
  </div>
);

const PrevArrow = ({ onClick }) => (
  <div
    className="absolute top-1/2 transform -translate-y-1/2 -left-8 z-10 cursor-pointer w-12 h-12 bg-black flex justify-center items-center opacity-50 text-white"
    onClick={onClick}
  >
    <IoIosArrowBack size={30} />
  </div>
);

const MainCategories = ({ slug }) => {
  const categories = useSelector((state) => state.maincategory.categories);
  const id = categories?.find(
    (category) =>
      category?.slug.toLowerCase() === slug ||
      category?.slug.toLowerCase() === slug?.slice(0, -1)
  )?.id;

  // Filter categories to include only those with parent name "Mens"
  const filteredCategories =
    categories?.filter((category) => category?.parent_id === id) || [];

  const settings = {
    dots: false,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 2,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
          arrows: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: true,
          infinite: true,
        },
      },
    ],
  };

  return (
    <div className="container pt-12 text-center">
      <Slider {...settings}>
        {filteredCategories?.map((category, index) => (
          <Link to={category?.slug} key={index}>
            <div className="hover:border hover:border-black duration-300 mx-3 h-[10rem] w-[10rem]">
              <img
                src={category?.photo}
                className="h-full w-full object-cover"
                alt={category?.name}
              />
              <p className="">{category?.name}</p>
            </div>
          </Link>
        ))}
      </Slider>
    </div>
  );
};

export default MainCategories;
