import { CssBaseline, ThemeProvider } from "@mui/material";
import { HelmetProvider } from "react-helmet-async";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import "./App.css";
import { CartProvider } from "./context/CartContext";
import store from "./redux/store";
import { Router } from "./router/routes";
import { theme } from "./theme";

function App() {
  return (
    <div className="App">
      <HelmetProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Provider store={store}>
            <CartProvider>
              <Router />
            </CartProvider>
          </Provider>
        </ThemeProvider>

        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </HelmetProvider>
    </div>
  );
}

export default App;
