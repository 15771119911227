import { Box } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Navbar from "../components/Navbar";
import { getBanners } from "../redux/features/BannerSlice";
import { getCategory } from "../redux/features/CategorySlice";
import { fetchMe } from "../redux/features/LoginSlice";
import { getWishlist } from "../redux/features/WishlistSlice";
import { fetchCartItems } from "../redux/features/cartSlice";

const Layout = ({ children }) => {
  const dispatch = useDispatch();
  const headerRef = useRef(null);
  const [prevScrollPos, setPrevScrollPos] = useState(window.scrollY);
  const [isNavbarVisible, setIsNavbarVisible] = useState(true);
  const [menuSlug, setMenuSlug] = useState("/");
  const [visibleHeight, setVisibleHeight] = useState(32);

  const me = useSelector((state) => state.login.me);
  // const meLoading = useSelector((state) => state.login.isLoading);
  // const categoryLoading = useSelector((state) => state.maincategory.isLoading);
  // const wishListLoading = useSelector((state) => state.wishlist.isLoading);
  // const cartLoading = useSelector((state) => state.cart.isLoading);

  useEffect(() => {
    dispatch(fetchMe());
    dispatch(getCategory());
    dispatch(getBanners());
  }, [dispatch]);

  useEffect(() => {
    if (me?.id) {
      dispatch(getWishlist({ user_id: me?.id }));
      dispatch(fetchCartItems({ user_id: me?.id }));
    }
  }, [dispatch, me?.id]);

  useEffect(() => {
    const rect = headerRef.current.getBoundingClientRect();
    const visiblePartHeight = Math.max(
      0,
      Math.min(rect.bottom, window.innerHeight) - Math.max(rect.top, 0)
    );

    setVisibleHeight(visiblePartHeight);

    const handleScroll = () => {
      const currentScrollPos = window.scrollY;
      // const isScrollingUp = prevScrollPos > currentScrollPos;

      // setIsNavbarVisible(isScrollingUp || currentScrollPos < 100);
      setIsNavbarVisible(currentScrollPos < 100);
      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollPos]);

  return (
    <Box>
      <Header ref={headerRef} />

      <div
        className={`${
          true ? "sticky top-0 w-full z-50" : "hidden"
        } transition-transform duration-300 bg-white shadow-md`}
      >
        <Navbar
          menuSlug={menuSlug}
          setMenuSlug={setMenuSlug}
          visibleHeight={visibleHeight}
        />
      </div>
      <Box
        sx={
          {
            // mt: "2rem",
          }
        }
      >
        {children}
      </Box>
      <Footer setMenuSlug={setMenuSlug} />
    </Box>
  );
};

export default Layout;
