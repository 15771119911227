import { Navigate, useRoutes } from "react-router-dom";
import AuthGuard from "../layouts/AuthGuard";
import Layout from "../layouts/Layout";
import AllProducts from "../pages/AllProducts";
import CartPage from "../pages/CartPage";
import Checkout from "../pages/Checkout";
import Landing from "../pages/Landing";
import Login from "../pages/Login";
import MainCategoryProduct from "../pages/MainCategoryProduct";
import NotFound from "../pages/NotFound";
import OrderPlaced from "../pages/OrderPlaced";
import OrderTrackingPage from "../pages/OrderTrackingPage";
import OTPpage from "../pages/OTPpage";
import Privacy from "../pages/Privacy";
import ProductDetail from "../pages/ProductDetail";
import Register from "../pages/Register";
import SearchedProducts from "../pages/SearchedProducts";
import SubCategoryProduct from "../pages/SubCategoryProduct";
import Terms from "../pages/Terms";
import UserDashboard from "../pages/UserDashboard";
import WishList from "../pages/WishList";

export const Router = () => {
  return useRoutes([
    {
      path: "/",
      children: [
        {
          element: (
            <Layout>
              <Landing />
            </Layout>
          ),
          index: true,
        },

        {
          path: "/login",
          element: (
            <Layout>
              <Login />
            </Layout>
          ),
        },

        {
          path: "/register",
          element: (
            <Layout>
              <Register />
            </Layout>
          ),
        },

        {
          path: "/wishlist",
          element: (
            <Layout>
              <AuthGuard>
                <WishList />
              </AuthGuard>
            </Layout>
          ),
        },

        {
          path: "/dashboard",
          element: (
            <Layout>
              <AuthGuard>
                <UserDashboard />
              </AuthGuard>
            </Layout>
          ),
        },

        {
          path: "/otp-page",
          element: (
            <Layout>
              <OTPpage />
            </Layout>
          ),
        },

        {
          path: "/cart",
          element: (
            <Layout>
              <AuthGuard>
                <CartPage />
              </AuthGuard>
            </Layout>
          ),
        },

        {
          path: "/search",
          element: (
            <Layout>
              <SearchedProducts />
            </Layout>
          ),
        },

        {
          path: "/products",
          element: (
            <Layout>
              <AllProducts />
            </Layout>
          ),
        },

        {
          path: "/offer-products",
          element: (
            <Layout>
              <AllProducts offer={true} />
            </Layout>
          ),
        },

        {
          path: "/tracking",
          element: (
            <Layout>
              <OrderTrackingPage />
            </Layout>
          ),
        },

        {
          path: "/checkout",
          element: (
            <Layout>
              <AuthGuard>
                <Checkout />
              </AuthGuard>
            </Layout>
          ),
        },

        {
          path: "/confirm-order",
          element: (
            <Layout>
              <AuthGuard>
                <OrderPlaced />
              </AuthGuard>
            </Layout>
          ),
        },

        {
          path: "/privacy",
          element: (
            <Layout>
              <Privacy />
            </Layout>
          ),
        },

        {
          path: "/terms",
          element: (
            <Layout>
              <Terms />
            </Layout>
          ),
        },

        {
          path: "/:slug",
          element: (
            <Layout>
              <MainCategoryProduct />
            </Layout>
          ),
        },

        {
          path: "/:main/:sub",
          element: (
            <Layout>
              <SubCategoryProduct />
            </Layout>
          ),
        },

        {
          path: "/product/:slug",
          element: (
            <Layout>
              <ProductDetail />
            </Layout>
          ),
        },
      ],
    },

    {
      path: "/404",
      element: (
        <Layout>
          <NotFound />
        </Layout>
      ),
    },

    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
};
