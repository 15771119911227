/* eslint-disable react-hooks/exhaustive-deps */
import { IconButton } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import NoData from "../components/NoData";
import SingleProduct from "../components/product/SingleProduct";
import { getProducts } from "../redux/features/ProductSlice";

const SubCategoryProduct = () => {
  const { main, sub } = useParams();
  const scrollContainerRef = useRef(null);
  const dispatch = useDispatch();

  const [activeTab, setActiveTab] = useState(sub);

  const categories = useSelector((state) => state.maincategory.categories);
  const products = useSelector((state) => state.mainproduct.products);

  const mainCategory = categories?.find((cat) => cat?.slug === main);
  const subCategory = categories?.find((cat) => cat?.slug === sub);

  // TODO: get the parent category of the sub if exists, if not exists then itself is parent
  const parentId = categories?.find((cat) => cat?.slug === sub)?.parent
    ?.parent_id
    ? categories?.find((cat) => cat?.slug === sub)?.parent_id
    : categories?.find((cat) => cat?.slug === sub)?.id;

  const parentCategory = categories?.find((cat) => cat?.id === parentId);

  const filteredCategories = categories?.filter(
    (cat) => cat?.parent_id === parentId
  );

  const tabData = filteredCategories?.map((cat) => ({
    label: cat?.name,
    value: cat?.slug,
    id: cat?.id,
  }));

  useEffect(() => {
    if (!tabData?.find((data) => data?.value === sub)) {
      setActiveTab("");
    } else {
      setActiveTab(sub);
    }
  }, [sub]);

  useEffect(() => {
    if (activeTab !== "") {
      const id = tabData?.find((data) => data?.value === activeTab)?.id;
      if (id) {
        dispatch(
          getProducts({
            limit: 20,
            sub_sub_category_id: id,
          })
        );
      }
    } else {
      if (parentId) {
        dispatch(
          getProducts({
            limit: 20,
            sub_category_id: parentId,
          })
        );
      }
    }
  }, [dispatch, activeTab, parentId]);

  const scrollLeft = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({
        left: -window.innerWidth,
        behavior: "smooth",
      });
    }
  };

  const scrollRight = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({
        left: window.innerWidth,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <div className="container pt-gap">
      <Helmet>
        <title>
          {`${main.charAt(0).toUpperCase() + main.slice(1, main.length)}'s ${
            activeTab !== ""
              ? tabData?.find((d) => d?.value === activeTab)?.label
              : subCategory?.name
          }
          | Citygarms`}
        </title>
      </Helmet>
      <div className="flex flex-col gap-5">
        {subCategory?.title && subCategory?.title !== "undefined" ? (
          <h1 className="uppercase italic tracking-widest font-medium text-3xl">
            {subCategory?.title}
          </h1>
        ) : (
          <h1 className="uppercase italic tracking-widest font-medium text-3xl">
            {main}&apos;s{" "}
            {activeTab !== ""
              ? tabData?.find((d) => d?.value === activeTab)?.label
              : subCategory?.name}
          </h1>
        )}
        <p className="md:max-w-[70%] w-full tracking-wider">
          {subCategory?.description && subCategory?.description !== "undefined"
            ? subCategory?.description
            : `Shop adidas for all styles of men's shoes including casual sneakers,
          high-performance running shoes, and comfy slides. Browse styles for
          women and kids here. Gear up on trending styles for summer. Save 30%
          when you add 2 or more items to your cart. Use code* SAVINGS. Offer
          ends 07/30. Exclusions apply.`}
        </p>

        <div className="relative flex items-center">
          <IconButton onClick={scrollLeft}>
            <IoIosArrowBack />
          </IconButton>
          <div
            className="flex border-b border-gray-200 overflow-y-hidden overflow-x-auto custom-scrollbar"
            ref={scrollContainerRef}
          >
            {[
              {
                label: `All ${
                  mainCategory?.name?.charAt(0)?.toUpperCase() +
                  mainCategory?.name?.substring(1, mainCategory?.name?.length)
                }'s ${
                  parentCategory?.name?.charAt(0)?.toUpperCase() +
                  parentCategory?.name?.substring(
                    1,
                    parentCategory?.name?.length
                  )
                }`,
                value: "",
              },
              ...tabData,
            ]?.map((tab) => (
              <Link
                to={`/${mainCategory?.slug}/${
                  tab.value !== "" ? tab.value : parentCategory?.slug
                }`}
                key={tab.value}
                className={`px-4 py-2 text-sm font-medium border-b-4 w-fit cursor-pointer ${
                  activeTab === tab.value
                    ? "border-black text-black"
                    : "border-transparent text-gray-600 hover:text-gray-700 hover:border-gray-300"
                }`}
                // onClick={() => setActiveTab(tab.value)}
              >
                <span className="text-nowrap text-sm font-normal">
                  {tab.label}
                </span>
              </Link>
            ))}
          </div>
          <IconButton onClick={scrollRight}>
            <IoIosArrowForward />
          </IconButton>
        </div>
      </div>

      <div className="mt-gap">
        {products?.length ? (
          <div className="grid grid-cols-1 md:grid-cols-3 gap-5 sm:grid-cols-2 lg:grid-cols-4 ">
            {products?.map((prod, index) => (
              <SingleProduct key={index} product={prod} />
            ))}
          </div>
        ) : (
          <NoData />
        )}
      </div>
    </div>
  );
};

export default SubCategoryProduct;
