import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getOrderCode } from "../redux/features/orderSlice";

const OrderTrackingPage = () => {
  const dispatch = useDispatch();
  const orderStatus = useSelector((state) => state.order.orderStatus);
  const isLoading = useSelector((state) => state.order.isLoading);
  const [trackingNumber, setTrackingNumber] = useState("");
  const [noDataFound, setNoDataFound] = useState(false);

  const handleTrackingNumberChange = (e) => {
    setTrackingNumber(e.target.value);
  };

  const code = trackingNumber;

  const trackOrder = () => {
    dispatch(getOrderCode({ code })).then((response) => {
      if (!response.payload || Object.keys(response.payload).length === 0) {
        setNoDataFound(true);
      } else {
        setNoDataFound(false);
      }
    });
  };

  return (
    <div className="bg-tracking min-h-[70vh] md:min-h-[80vh] bg-cover bg-center flex items-center justify-center">
      <div className="bg-white bg-opacity-90 p-8 w-[90%] md:w-[50%] lg:w-[40%] rounded-md shadow-lg">
        <h1 className="text-3xl text-center font-semibold mb-4 text-gray-800">
          Track Your Order
        </h1>
        <div className="flex mb-4 gap-5">
          <input
            type="text"
            value={trackingNumber}
            onChange={handleTrackingNumberChange}
            placeholder="Enter tracking number"
            className="border rounded-md border-gray-300 px-4 py-2 w-full focus:outline-none"
          />
          <button
            disabled={isLoading}
            onClick={() => {
              if (trackingNumber !== "") {
                trackOrder();
              }
            }}
            className="flex-1 text-white px-5 py-2 rounded-md  bg-black focus:outline-none"
          >
            {isLoading ? "Tracking ..." : "Track"}
          </button>
        </div>
        {noDataFound && (
          <div className="text-center">
            <h2 className="font-medium text-xl">No order data found</h2>
            <p className="font-light text-md">Try different code</p>
          </div>
        )}
        {orderStatus && !noDataFound && (
          <div className="border rounded-md border-gray-300 p-4">
            <h2 className="text-xl font-semibold mb-2">Order Status</h2>
            <p>
              <span className="font-semibold">Status: </span>
              {orderStatus?.status}
            </p>
            <p>
              <span className="font-semibold">Payment Mode: </span>
              {orderStatus?.payment_mode}
            </p>
            <p>
              <span className="font-semibold">Total: </span>
              Rs.{orderStatus?.total}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default OrderTrackingPage;
