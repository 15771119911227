// import React from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Heading from "../../components/Heading";

const MainCategory = () => {
  const categories = useSelector((state) => state.maincategory.categories);

  const desiredOrder = ["Women", "Men", "Kids", "Home"];

  const sortCategories = (categories) => {
    return categories?.sort((a, b) => {
      return desiredOrder.indexOf(a.name) - desiredOrder.indexOf(b.name);
    });
  };

  const filteredCategory = (categories?.data || categories)?.filter(
    (category) => !category?.parent_id
  );

  const mainCategories = sortCategories([...filteredCategory]);

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <div className="container">
      <Heading title="Categories" />
      <div className="grid md:grid-cols-3 grid-cols-1 xs:grid-cols-2 gap-y-5 lg:grid-cols-4  gap-x-5 text-center">
        {mainCategories?.map((cat, index) => (
          <Link to={`/${cat?.slug}`} key={index}>
            <div className="hover:border-black duration-300 hover:border">
              <img
                src={cat?.photo}
                alt="citygarms categories"
                className="object-contain"
              />
              <span className="uppercase">{cat?.name}</span>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default MainCategory;
