import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  MenuItem,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { IoMdClose } from "react-icons/io";
import { useDispatch } from "react-redux";
import { deleteCartItem, updateCart } from "../redux/features/cartSlice";

const CartCard = ({ item }) => {
  const dispatch = useDispatch();

  const [openDialog, setOpenDialog] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);

  const handleOpenDialog = (id) => {
    setSelectedItemId(id);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedItemId(null);
  };

  const handleDelete = (id) => {
    dispatch(deleteCartItem(id));
    setOpenDialog(false);
  };

  return (
    <div className="relative shadow bg-white rounded-lg overflow-hidden flex flex-wrap justify-between items-center space-y-2 p-4">
      <img
        src={item?.product.photo}
        alt={item?.product.name}
        className="sm:h-44 object-cover mr-4 rounded-md pt-8 pb-4 sm:pb-0 sm:pt-0"
      />
      <div className="flex-1">
        <div className="flex-col">
          <p className="font-semibold text-lg">{item?.product?.name}</p>
          <p className="mt-2 font-bold">Rs. {item?.price}</p>
        </div>
        <div className="lg:flex items-center gap-12">
          <div className="flex items-center gap-3">
            <label className="block text-sm font-medium text-gray-700">
              Size:
            </label>
            <FormControl fullWidth>
              <TextField
                defaultValue={item?.variation?.id}
                select
                size="small"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                onChange={(e) => {
                  dispatch(
                    updateCart({
                      id: item?.id,
                      data: {
                        variation_id: e.target.value,
                      },
                    })
                  );
                }}
              >
                {item?.product?.variations?.map((variation) => (
                  <MenuItem key={variation.size.id} value={variation?.id}>
                    <span>{variation?.size?.name}</span>
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          </div>
          <div className="flex items-center space-x-2 py-3">
            <label className="block text-sm font-medium text-gray-700">
              Qty:
            </label>
            <button
              disabled={item?.qty === 1}
              className="px-3 bg-gray-100 py-1 border rounded"
              onClick={() =>
                dispatch(
                  updateCart({
                    id: item?.id,
                    data: {
                      qty: item?.qty - 1,
                    },
                  })
                )
              }
            >
              -
            </button>
            <span>{item?.qty}</span>
            <button
              className="px-3 bg-gray-100 py-1 border rounded"
              onClick={() =>
                dispatch(
                  updateCart({
                    id: item?.id,
                    data: {
                      qty: item?.qty + 1,
                    },
                  })
                )
              }
            >
              +
            </button>
          </div>
        </div>
      </div>
      <button
        className="text-black px-4 py-2 text-start absolute top-0 right-0"
        onClick={() => handleOpenDialog(item?.id)}
      >
        <IoMdClose className="text-2xl " />
      </button>

      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Delete Cart Item?"}</DialogTitle>
        <DialogContent>
          <p>Are you sure you want to delete this item from your cart?</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => handleDelete(selectedItemId)}
            color="primary"
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CartCard;
