import React from "react";

const Terms = () => {
  return (
    <div className="min-h-screen bg-gray-100 py-10 px-5 md:px-20 lg:px-40">
      <div className="bg-white p-10 rounded-lg shadow-lg">
        <h1 className="text-3xl font-bold mb-6 text-center">
          Terms and Conditions
        </h1>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">1. Introduction</h2>
          <p className="text-gray-700 leading-6">
            Welcome to our website. By accessing or using our website, you agree
            to comply with and be bound by the following terms and conditions.
            Please review these terms carefully. If you do not agree with these
            terms, you should not use this website.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">
            2. Intellectual Property Rights
          </h2>
          <p className="text-gray-700 leading-6">
            Other than the content you own, under these terms, we own all the
            intellectual property rights and materials contained in this
            website. You are granted a limited license only for purposes of
            viewing the material contained on this website.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">3. Restrictions</h2>
          <p className="text-gray-700 leading-6">
            You are specifically restricted from all of the following:
            <ul className="list-disc list-inside ml-4 mt-2">
              <li>Publishing any website material in any other media</li>
              <li>
                Selling, sublicensing and/or otherwise commercializing any
                website material
              </li>
              <li>Publicly performing and/or showing any website material</li>
              <li>
                Using this website in any way that is or may be damaging to this
                website
              </li>
              <li>
                Using this website in any way that impacts user access to this
                website
              </li>
              <li>
                Engaging in any data mining, data harvesting, data extracting or
                any other similar activity in relation to this website
              </li>
              <li>
                Using this website to engage in any advertising or marketing
              </li>
            </ul>
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">4. Your Content</h2>
          <p className="text-gray-700 leading-6">
            In these terms and conditions, “your content” shall mean any audio,
            video text, images or other material you choose to display on this
            website. By displaying your content, you grant us a non-exclusive,
            worldwide irrevocable, sub-licensable license to use, reproduce,
            adapt, publish, translate and distribute it in any and all media.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">5. No warranties</h2>
          <p className="text-gray-700 leading-6">
            This website is provided “as is,” with all faults, and we express no
            representations or warranties, of any kind related to this website
            or the materials contained on this website. Also, nothing contained
            on this website shall be interpreted as advising you.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">
            6. Limitation of liability
          </h2>
          <p className="text-gray-700 leading-6">
            In no event shall we, nor any of our officers, directors, and
            employees, be held liable for anything arising out of or in any way
            connected with your use of this website whether such liability is
            under contract. We shall not be held liable for any indirect,
            consequential or special liability arising out of or in any way
            related to your use of this website.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">7. Indemnification</h2>
          <p className="text-gray-700 leading-6">
            You hereby indemnify to the fullest extent us from and against any
            and all liabilities, costs, demands, causes of action, damages and
            expenses arising in any way related to your breach of any of the
            provisions of these terms.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">8. Severability</h2>
          <p className="text-gray-700 leading-6">
            If any provision of these terms is found to be invalid under any
            applicable law, such provisions shall be deleted without affecting
            the remaining provisions herein.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">9. Variation of Terms</h2>
          <p className="text-gray-700 leading-6">
            We are permitted to revise these terms at any time as we see fit,
            and by using this website you are expected to review these terms on
            a regular basis.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">10. Assignment</h2>
          <p className="text-gray-700 leading-6">
            We are allowed to assign, transfer, and subcontract our rights
            and/or obligations under these terms without any notification.
            However, you are not allowed to assign, transfer, or subcontract any
            of your rights and/or obligations under these terms.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">11. Entire Agreement</h2>
          <p className="text-gray-700 leading-6">
            These terms constitute the entire agreement between us and you in
            relation to your use of this website and supersede all prior
            agreements and understandings.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">
            12. Governing Law & Jurisdiction
          </h2>
          <p className="text-gray-700 leading-6">
            These terms will be governed by and interpreted in accordance with
            the laws of the State of [Your State], and you submit to the
            non-exclusive jurisdiction of the state and federal courts located
            in [Your State] for the resolution of any disputes.
          </p>
        </section>

        <p className="text-center text-gray-600 mt-8">
          If you have any questions about these Terms, please contact us at
          [Your Contact Information].
        </p>
      </div>
    </div>
  );
};

export default Terms;
