import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import SingleProduct from "../components/product/SingleProduct";
import { getProducts } from "../redux/features/ProductSlice";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const SearchedProducts = () => {
  const dispatch = useDispatch();
  const products = useSelector((state) => state.mainproduct.searchedProducts);
  const loading = useSelector((state) => state.mainproduct.isLoading);
  const query = useQuery();
  const searchQuery = query.get("q") || "";

  useEffect(() => {
    dispatch(getProducts({ limit: 50, search: searchQuery }));
  }, [dispatch, searchQuery]);

  return (
    <div className="container md:pb-14 bg-white overflow-hidden">
      {loading ? (
        <div className="flex justify-center h-[calc(100vh-4rem)] items-center">
          <div className="loader"></div>
        </div>
      ) : products?.length === 0 ? (
        <div className="text-center py-12 md:pt-24 px-4 md:px-0">
          <img
            src="/images/nodataimg.png"
            className="sm:w-[60%] lg:w-[40%] mx-auto pb-8"
            alt=""
          />
          <h2 className="uppercase font-semibold text-3xl">
            opps no result for search product "{searchQuery}"
          </h2>
          <p>Don&apos;t give up! Check the spelling or try something else.</p>
        </div>
      ) : (
        <div>
          <h2 className="py-8 pt-10 italic text-2xl font-semibold uppercase">
            Search results of "{searchQuery}"
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-y-6">
            {products?.map((product, index) => (
              <SingleProduct key={index} product={product} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default SearchedProducts;
