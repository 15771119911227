import { Box, Skeleton, Stack } from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import DropdownCategories from "./DropdownCategories";
import NavbarOpt from "./NavbarOpt";

const Navbar = ({ menuSlug, setMenuSlug, visibleHeight }) => {
  // const [popupVisible, setPopupVisible] = useState(false);
  const [isMenDropdownOpen, setIsMenDropdownOpen] = useState(false);
  const [subCategoryData, setSubCategoryData] = useState(null);
  const [filterSubCategory, setFilterSubCategory] = useState([]);

  const [subDropdowns, setSubDropdowns] = useState({});

  const [dropdowns, setDropdowns] = useState({
    womens: false,
    mens: false,
    kids: false,
    home: false,
  });

  const closeMenu = () => {
    setIsMenDropdownOpen(false);
  };

  const categories = useSelector((state) => state.maincategory.categories);
  const isLoading = useSelector((state) => state.maincategory.isLoading);

  const filteredCategory = (categories?.data || categories)?.filter(
    (category) => !category?.parent_id
  );

  const updateFilterSubCategory = (parentId) => {
    const filteredSubCategory = (categories?.data || categories)?.filter(
      (cat) => cat?.parent?.parent_id === parentId
    );
    setFilterSubCategory(filteredSubCategory);
  };

  // const desiredOrder = ["WOMEN", "MEN", "KIDS", "HOME & LIVING"];

  const hoverEffectFunc = (id, slug) => {
    setMenuSlug(slug);
    setIsMenDropdownOpen(true);
    const category = hoverFilterCategory(id);
    setSubCategoryData(category);
    updateFilterSubCategory(id);
  };

  const hoverLeaveMouseEffectFunc = () => {
    setIsMenDropdownOpen(false);
    setSubCategoryData(null);
    setMenuSlug("/");
  };

  const hoverFilterCategory = (id) => {
    const filteredCategory = (categories?.data || categories)?.filter(
      (category) => category?.parent_id === id
    );
    return filteredCategory;
  };

  // const sortCategories = (categories) => {
  //   return categories?.sort((a, b) => {
  //     return desiredOrder.indexOf(a.name) - desiredOrder.indexOf(b.name);
  //   });
  // };

  const toggleDropdown = (category) => {
    setDropdowns((prev) => ({ ...prev, [category]: !prev[category] }));
  };

  const toggleSubDropdown = (subcategory) => {
    setSubDropdowns((prev) => ({ ...prev, [subcategory]: !prev[subcategory] }));
  };

  // const sortedCategories = sortCategories([...filteredCategory]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <Box className="flex justify-between h-[4rem] items-center navContainer">
      {isLoading ? (
        <div className="w-full">
          <Skeleton variant="rectangular" />
          <Skeleton variant="rectangular" />
        </div>
      ) : (
        <>
          <div className="flex items-center gap-5">
            <Stack flexDirection={"row"} gap={5} alignItems={"center"}>
              <Link to={"/"}>
                <img src="/assets/logo.png" alt="Logo" className="h-[3rem]" />
              </Link>

              <div className="hidden gap-7 md:flex">
                {filteredCategory?.map((filtercat, index) => (
                  <div key={index} className="relative flex items-center">
                    <Link
                      onMouseEnter={() =>
                        hoverEffectFunc(filtercat?.id, `/${filtercat?.slug}`)
                      }
                      // onMouseLeave={hoverLeaveMouseEffectFunc}
                      onClick={closeMenu}
                      to={`/${filtercat?.slug}`}
                      className="h-[4rem] flex items-center tracking-wider"
                    >
                      <span className="text-base font-[400] tracking-wider">
                        {filtercat?.name}
                      </span>
                    </Link>

                    {/* TODO: underline when hovered */}
                    <span
                      className={`absolute bottom-0 left-0 w-full h-[4px] bg-black duration-150 origin-left scale-x-0 ${
                        (menuSlug?.includes("/" + filtercat?.slug) ||
                          window.location.pathname.includes(
                            "/" + filtercat?.slug
                          )) &&
                        "scale-x-100"
                      }`}
                    />

                    {isMenDropdownOpen && (
                      <DropdownCategories
                        visibleHeight={visibleHeight}
                        hoverLeaveMouseEffectFunc={hoverLeaveMouseEffectFunc}
                        subCategoryData={subCategoryData}
                        hoverEffectFunc={hoverEffectFunc}
                        closeMenu={closeMenu}
                        filterSubCategory={filterSubCategory}
                      />
                    )}
                  </div>
                ))}
              </div>
            </Stack>
          </div>
          <NavbarOpt
            categories={categories}
            dropdowns={dropdowns}
            scrollToTop={scrollToTop}
            sortedCategories={filteredCategory}
            subDropdowns={subDropdowns}
            toggleDropdown={toggleDropdown}
            toggleSubDropdown={toggleSubDropdown}
          />
        </>
      )}
    </Box>
  );
};

export default Navbar;
