/* eslint-disable react-hooks/exhaustive-deps */
import { Divider } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { IoIosHeart, IoMdHeartEmpty } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BreadCrumb from "../components/BreadCrumb";
import { postCart } from "../redux/features/cartSlice";
import { getSingleProduct } from "../redux/features/ProductSlice";
import { deleteWishlist, postWishlist } from "../redux/features/WishlistSlice";
import Recommendation from "../sections/detail/Recommendation";

const ProductDetail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { slug } = useParams();
  const [selectedImage, setSelectedImage] = useState("");
  const [quantity, setQuantity] = useState(1);
  const [selectedSize, setSelectedSize] = useState(null);
  const [selectedColor, setSelectedColor] = useState(null);
  const [selectedVariation, setSelectedVariation] = useState(null);
  const wishLists = useSelector((state) => state.wishlist.wishlist);
  const isLoading = useSelector((state) => state.wishlist.isLoading);
  const me = useSelector((state) => state.login.me);

  const singleProduct = useSelector(
    (state) => state.mainproduct?.singleProduct
  );
  const cart = useSelector((state) => state.cart.carts);

  useEffect(() => {
    dispatch(getSingleProduct({ slug: slug }));
  }, [dispatch, slug]);

  useEffect(() => {
    if (singleProduct) {
      setSelectedImage(singleProduct.photo);
      if (singleProduct.variations.length > 0) {
        setSelectedSize(singleProduct.variations[0]?.size);
        setSelectedColor(singleProduct.variations[0]?.color);
      }
    }
  }, [singleProduct]);

  const isDuplicate = cart?.some(
    (cartItem) =>
      cartItem.product_id === singleProduct?.id &&
      cartItem.variation_id === selectedVariation?.id
  );

  const alreadyWished = wishLists.some(
    (item) => item?.product_id === singleProduct?.id
  );

  const toggleHeart = () => {
    toast.dismiss();
    if (!me) {
      navigate("/register");
    }
    if (alreadyWished) {
      dispatch(
        deleteWishlist({
          id: wishLists?.find((data) => data?.product?.id === singleProduct?.id)
            ?.id,
        })
      );
    } else {
      dispatch(
        postWishlist({ user_id: me?.id, product_id: singleProduct?.id })
      );
    }
  };

  const handleAddToCart = () => {
    if (!selectedSize || !selectedColor) {
      toast.error("Please select a size and color");
      return;
    }

    if (!selectedVariation) {
      toast.error("Selected variation not found");
      return;
    }

    const cartItem = {
      user_id: me?.id,
      product_id: singleProduct?.id,
      variation_id: selectedVariation?.id,
      price: selectedVariation?.selling_price,
      qty: quantity,
      size: selectedSize,
      color: selectedColor,
    };

    // Check for duplicates based on product_id and variation_id

    if (isDuplicate) {
      toast.error("Item already in cart");
    } else {
      dispatch(postCart(cartItem))
        .unwrap()
        .then(() => {
          toast.success("Successfully added to cart");
        })
        .catch((error) => {
          navigate("/register");
        });
    }
  };

  const incrementQuantity = () => {
    setQuantity((prevQuantity) => prevQuantity + 1);
  };

  const decrementQuantity = () => {
    if (quantity > 1) {
      setQuantity((prevQuantity) => prevQuantity - 1);
    }
  };

  const handleSizeClick = (size) => {
    setSelectedSize((prevSize) => (prevSize === size ? null : size));
  };

  const handleColorClick = (color) => {
    setSelectedColor((prevColor) => (prevColor === color ? null : color));
  };

  const handleTagClick = (tagName) => {
    navigate(`/search?q=${tagName?.toLowerCase()}`);
  };

  useEffect(() => {
    const variation = singleProduct?.variations?.find(
      (variation) =>
        variation?.size?.id === selectedSize?.id &&
        variation?.color?.id === selectedColor?.id
    );

    setSelectedVariation(variation);
  }, [selectedColor, selectedSize]);

  return (
    <div className="bg-white pt-gap container">
      <Helmet>
        <title>{singleProduct?.name || ""}| Citygarms</title>
      </Helmet>

      <BreadCrumb product={singleProduct} />

      <div className="mx-auto md:flex flex flex-col md:flex-row gap-10">
        <div className="flex flex-col-reverse lg:flex-row flex-1">
          <div className="flex flex-row lg:flex-col mr-3 gap-3">
            {singleProduct?.gallery.map((image, index) => (
              <img
                key={index}
                src={image}
                alt={`Product ${index}`}
                className={`w-24 aspect-[4/5] object-cover cursor-pointer rounded-lg ${
                  selectedImage === image
                    ? "border-2 border-black"
                    : "border border-transparent"
                }`}
                onClick={() => setSelectedImage(image)}
              />
            ))}
          </div>
          <div className="flex flex-col mb-7 lg:mb-0 w-full">
            <img
              src={selectedImage}
              alt="Selected Product"
              className="w-full object-cover rounded-lg aspect-[4/5]"
            />
          </div>
        </div>
        <div className="flex flex-col gap-4 flex-1">
          <h2 className="text-3xl font-semibold">{singleProduct?.name}</h2>
          <h3 className="font-medium">
            SKU: <span className="font-normal">{singleProduct?.sku}</span>
          </h3>
          {singleProduct?.brand ? (
            <h3 className="font-medium">
              Brand:{" "}
              <span className="font-normal">{singleProduct?.brand?.name}</span>
            </h3>
          ) : null}

          <Divider />

          {selectedVariation ? (
            <>
              {selectedVariation?.stock ? (
                <div className="flex items-center gap-3">
                  {selectedVariation?.price !==
                  selectedVariation?.selling_price ? (
                    <del className="text-xl font-bold text-[#3d3d3d]">
                      Rs. {selectedVariation?.price}
                    </del>
                  ) : null}
                  <p className="text-2xl font-semibold">
                    Rs. {selectedVariation?.selling_price}
                  </p>
                </div>
              ) : (
                <h1 className="uppercase text-red-500 font-extrabold">
                  Out of Stock
                </h1>
              )}
            </>
          ) : (
            <p className="text-2xl font-semibold text-red-500">
              No matched variation.
            </p>
          )}
          {singleProduct?.variations?.[0]?.size?.name === "Default" ? null : (
            <div className="flex gap-3 items-center">
              <label className="text-gray-600 font-medium">Size:</label>

              <div className="flex gap-3 flex-wrap">
                {[
                  ...new Set(
                    singleProduct?.variations?.map(
                      (variation) => variation.size?.name
                    )
                  ),
                ].map((size, index) => {
                  const variation = singleProduct.variations.find(
                    (variation) => variation.size?.name === size
                  );
                  return (
                    <button
                      disabled={selectedSize?.id === variation?.size?.id}
                      key={index}
                      className={`border border-gray-300 rounded-lg px-4 py-2 cursor-pointer duration-200 hover:border-black flex items-center gap-2`}
                      onClick={() => handleSizeClick(variation.size)}
                    >
                      <div
                        className={`w-4 h-4 rounded-full border border-black ${
                          selectedSize?.id === variation?.size?.id
                            ? "p-[2px]"
                            : ""
                        }`}
                      >
                        <div
                          className={`w-full h-full rounded-full ${
                            selectedSize?.id === variation?.size?.id
                              ? "bg-black text-white"
                              : ""
                          }`}
                        />
                      </div>
                      <span>{variation?.size?.name}</span>
                    </button>
                  );
                })}
              </div>
            </div>
          )}

          {singleProduct?.variations?.[0]?.color?.name === "Default" ? null : (
            <div className="flex gap-3 items-center">
              <label className="text-gray-600 font-medium">Color:</label>

              <div className="flex gap-3 flex-wrap">
                {[
                  ...new Set(
                    singleProduct?.variations?.map(
                      (variation) => variation.color?.name
                    )
                  ),
                ].map((colorName, index) => {
                  const variation = singleProduct.variations.find(
                    (variation) => variation.color?.name === colorName
                  );
                  return (
                    <button
                      disabled={selectedColor?.id === variation?.color?.id}
                      key={index}
                      className={`border border-gray-300 rounded-lg px-4 py-2 cursor-pointer duration-200 hover:border-black flex items-center gap-2`}
                      onClick={() => handleColorClick(variation.color)}
                    >
                      <div
                        className={`w-4 h-4 rounded-full border border-black ${
                          selectedColor?.id === variation?.color?.id
                            ? "p-[2px]"
                            : ""
                        }`}
                      >
                        <div
                          className={`w-full h-full rounded-full ${
                            selectedColor?.id === variation?.color?.id
                              ? "bg-black text-white"
                              : ""
                          }`}
                        />
                      </div>

                      <span>{variation?.color?.name}</span>
                    </button>
                  );
                })}
              </div>
            </div>
          )}
          <div className="flex xs:flex-row flex-col md:flex-row  gap-5">
            <div className="flex items-center gap-3">
              <button
                className="p-2 border rounded-lg w-10"
                onClick={decrementQuantity}
                disabled={quantity === 1}
              >
                -
              </button>
              <span className="px-4 py-2 border rounded-lg">{quantity}</span>
              <button
                className="p-2 border rounded-lg w-10"
                onClick={incrementQuantity}
              >
                +
              </button>
            </div>
          </div>

          <div className="flex gap-3 flex-wrap">
            <button
              disabled={isDuplicate || !selectedVariation}
              className={`px-6 w-fit py-2 ${
                isDuplicate ? "bg-gray-300" : "bg-black text-white"
              } rounded-lg`}
              onClick={handleAddToCart}
            >
              {isDuplicate ? "Already added to cart" : "Add to Cart"}
            </button>
            <button className="p-2 rounded-lg flex items-center border">
              {alreadyWished ? (
                <button
                  disabled={isLoading}
                  onClick={toggleHeart}
                  className="flex items-center"
                >
                  <IoIosHeart className=" cursor-pointer text-2xl top-3 right-3 backdrop-blur-sm text-red-600" />
                  <span className="ml-2">Add to Wishlist</span>
                </button>
              ) : (
                <button
                  disabled={isLoading}
                  className="flex items-center"
                  onClick={toggleHeart}
                >
                  <IoMdHeartEmpty className=" cursor-pointer text-2xl top-3 right-3 backdrop-blur-sm" />
                  <span className="ml-2">Add to Wishlist</span>
                </button>
              )}
            </button>
          </div>
          <div className="flex gap-3 items-center flex-wrap">
            <div className="flex gap-3 flex-wrap">
              {singleProduct?.tags.map((tag) => (
                <span
                  key={tag.id}
                  className="cursor-pointer hover:underline bg-[#F4F6F8] px-4 rounded-full mt-4 py-1"
                  onClick={() => handleTagClick(tag.name)}
                >
                  {tag.name}
                </span>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div>
        <h2 className="text-xl font-medium pt-14">Description</h2>
        <p
          className="py-2"
          dangerouslySetInnerHTML={{ __html: singleProduct?.description }}
        ></p>
      </div>

      {/* TODO: recommendation */}
      <Recommendation
        sub_category_id={singleProduct?.sub_category_id}
        id={singleProduct?.id}
      />
    </div>
  );
};

export default ProductDetail;
