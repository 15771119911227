import React from "react";

const Privacy = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-6">
      <div className="bg-white shadow-lg rounded-lg p-8 max-w-2xl w-full">
        <h1 className="text-3xl font-semibold text-gray-800 mb-4">
          Privacy Policy
        </h1>
        <p className="text-gray-600 mb-4">
          Your privacy is important to us. It is our policy to respect your
          privacy regarding any information we may collect from you across our
          website, [website], and other sites we own and operate.
        </p>
        <h2 className="text-2xl font-semibold text-gray-800 mb-2">
          Information We Collect
        </h2>
        <p className="text-gray-600 mb-4">
          We only ask for personal information when we truly need it to provide
          a service to you. We collect it by fair and lawful means, with your
          knowledge and consent. We also let you know why we’re collecting it
          and how it will be used.
        </p>
        <h2 className="text-2xl font-semibold text-gray-800 mb-2">
          How We Use Information
        </h2>
        <p className="text-gray-600 mb-4">
          We only retain collected information for as long as necessary to
          provide you with your requested service. What data we store, we’ll
          protect within commercially acceptable means to prevent loss and
          theft, as well as unauthorized access, disclosure, copying, use, or
          modification.
        </p>
        <h2 className="text-2xl font-semibold text-gray-800 mb-2">
          Your Rights
        </h2>
        <p className="text-gray-600 mb-4">
          You are free to refuse our request for your personal information, with
          the understanding that we may be unable to provide you with some of
          your desired services.
        </p>
        <p className="text-gray-600 mb-4">
          Your continued use of our website will be regarded as acceptance of
          our practices around privacy and personal information. If you have any
          questions about how we handle user data and personal information, feel
          free to contact us.
        </p>
        <p className="text-gray-600 mb-4">
          This policy is effective as of [date].
        </p>
      </div>
    </div>
  );
};

export default Privacy;
