import { useState } from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import Login from "../pages/Login";

export default function AuthGuard({ children }) {
  const { pathname } = useLocation();

  const [requestedLocation, setRequestedLocation] = useState(null);

  const me = useSelector((state) => state.login.me);
  const error = useSelector((state) => state.login.error);

  if (me === null && error === null) {
    return (
      <div className="flex justify-center h-[calc(100vh-4rem)] items-center">
        <div className="loader"></div>
      </div>
    );
  }

  if (!me?.id) {
    // TODO: change this to !isAuthenticated
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <Login />;
  }

  if (!me?.phone_verified_at) {
    toast.info("Your phone has not verified yet. Please verify first.");
    return <Login />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <> {children} </>;
}
