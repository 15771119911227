import { Skeleton } from "@mui/material";
import React, { useRef } from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import NoData from "../../components/NoData";

const NextArrow = ({ onClick }) => (
  <div
    className="absolute top-1/2 w-12 h-12 bg-black flex justify-center items-center opacity-50 text-white transform -translate-y-1/2 right-0 z-10 cursor-pointer"
    onClick={onClick}
  >
    <IoIosArrowForward size={30} />
  </div>
);

const PrevArrow = ({ onClick }) => (
  <div
    className="absolute top-1/2 transform -translate-y-1/2 left-0 z-10 cursor-pointer w-12 h-12 bg-black flex justify-center items-center opacity-50 text-white"
    onClick={onClick}
  >
    <IoIosArrowBack size={30} />
  </div>
);

const settings = {
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
  // beforeChange: (oldIndex, newIndex) => setCurrentSlide(newIndex),
  // responsive: [
  //   {
  //     breakpoint: 3000,
  //     settings: {
  //       slidesToShow: 1,
  //       slidesToScroll: 1,
  //     },
  //   },
  //   {
  //     breakpoint: 1024,
  //     settings: {
  //       slidesToShow: 1,
  //       slidesToScroll: 1,
  //     },
  //   },

  //   {
  //     breakpoint: 630,
  //     settings: {
  //       slidesToShow: 2,
  //       slidesToScroll: 1,
  //     },
  //   },

  //   {
  //     breakpoint: 425,
  //     settings: {
  //       slidesToShow: 1,
  //       slidesToScroll: 1,
  //     },
  //   },
  // ],
};

const Banner = () => {
  const sliderRef = useRef(null);

  const landingBanner = useSelector((state) => state.banner.banners).filter(
    (b) => b?.category === null
  );

  const loading = useSelector((state) => state.banner.isLoading);

  return (
    <>
      {loading ? (
        <div className="">
          <Skeleton
            animation="wave"
            variant="rectangular"
            sx={{
              height: "80%",
            }}
          />
        </div>
      ) : (
        <div className="mb-gap">
          {landingBanner?.length ? (
            <div>
              {landingBanner?.length > 1 ? (
                <Slider adaptiveHeight={true} ref={sliderRef} {...settings}>
                  {landingBanner?.map((b, index) => (
                    <div>
                      <div key={index}>
                        <Link to="/products">
                          <img
                            alt=""
                            src={b?.image}
                            className="w-full object-center"
                          />
                        </Link>
                      </div>
                    </div>
                  ))}
                </Slider>
              ) : (
                <>
                  {landingBanner?.map((b, index) => (
                    <div key={index}>
                      <Link to="/products">
                        <img
                          alt=""
                          src={b?.image}
                          className="w-full object-center"
                        />
                      </Link>
                    </div>
                  ))}
                </>
              )}
            </div>
          ) : (
            <NoData />
          )}
        </div>
      )}
    </>
  );
};

export default Banner;
